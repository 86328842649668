import React from "react";

import classnames from "classnames";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";

function Table({ title, width = "tw-w-1/4", description, HeadTitles, TableItems }) {
  return (
    <div className="tw-py-2">
      <h4 className="bl-font-secondary tw-font-semibold bl-text-xl sm:bl-text-xl lg:bl-text-xl tw-leading-tight tw-mb-10">
        {title}
      </h4>
      <p className="tw-mx-1">{description}</p>
      <div className="tw-overflow-x-auto">
        <div className="tw-align-middle tw-inline-block tw-min-w-full tw-overflow-hidden bl-grayscale-0 tw-pt-3 tw-rounded-bl-lg tw-rounded-br-lg tw-w-160 md:tw-w-auto">
          <table className="tw-min-w-full bl-bg-primary-6 tw-rounded-t-lg tw-w-full">
            <thead>
              <tr>
                {HeadTitles.map((item, index) => {
                  if (!item.text) {
                    return null;
                  }

                  return (
                    <th
                      key={`th-${index}`}
                      className="tw-px-6 bl-font-secondary tw-font-semibold tw-text-left bl-text-base tw-leading-4 bl-text-primary tw-tracking-wider tw-py-6"
                    >
                      {item.text}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="bl-grayscale-0 bl-bg-grayscale-0 ">
              {TableItems.map((item, index) => {
                return (
                  <TableContentItem key={`TableContentItem-${index}`} width={width} item={item} />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default withErrorBoundaryHOC(Table);

// --- Components ---

function TableContentItem({ item, width }) {
  const keys = Object.keys(item);

  return (
    <tr className="hover:bl-bg-primary-6">
      {keys.map((key, index) => {
        if (!item[key] || key === "id") {
          return null;
        }

        return (
          <td
            key={`TableContentItem-key-${index}`}
            className={classnames(
              "tw-px-6 tw-py-6 tw-whitespace-no-wrap tw-border-b bl-border-grayscale bl-grayscale-30 bl-text-base tw-leading-5",
              width,
            )}
          >
            {item[key]}
          </td>
        );
      })}
    </tr>
  );
}
