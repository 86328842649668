import React from "react";

import classnames from "classnames";
import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";
import Table from "lib/@cms/components/cms/Table";
import BodyText from "lib/@cms/components/shared/BodyText";
import Divider from "lib/@cms/components/primitive/Divider";

function AutoLoans() {
  return (
    <Page pathname="auto-loans">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />
            <Divider className="tw-my-6" />

            <div className="tw-container">
              <Text variant={Text.variant.BODY}>{data.MetaData.description}</Text>
              <Divider className="tw-my-3" />

              {data.TextsLists?.map((item, index) => {
                const isLineSeparator = index === 0;

                return (
                  <React.Fragment key={`TextsList-${index}`}>
                    <BodyText data={item} variant={BodyText.variant.DECORATION_NONE} />
                    <Divider
                      className={classnames(
                        isLineSeparator ? "tw-my-24 tw-border tw-border-grayscale-6" : "tw-my-6",
                      )}
                    />
                  </React.Fragment>
                );
              })}
            </div>
            <div className="tw-container">
              {data.Tables?.map((table, index) => {
                return (
                  <div key={`TableContent-${index}`}>
                    <Table
                      title={table.title}
                      width="tw-w-2/12"
                      HeadTitles={table.HeadTitles}
                      TableItems={table.TableItems}
                    />
                    <Divider className="tw-my-6" />
                  </div>
                );
              })}
            </div>
            <Divider className="tw-my-7 sm:tw-my-16" />
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default AutoLoans;
